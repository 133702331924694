import { autoinject } from "aurelia-dependency-injection";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { User } from "components/models/UserModel";
import { default as env } from "../../../config/environment.json";
import { TransactionHttpClient } from "http_clients/TransactionHttpClients";
import { YapilyAccountIdentificationModel } from "components/models/YapilyModels/YapilyAccountIdentificationModel";
import { MenuItem } from "components/models/MenuItem";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { I18N } from "aurelia-i18n";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { SingletonService } from "singleton";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { EventAggregator } from "aurelia-event-aggregator";
import { computedFrom } from "aurelia-framework";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { Chart } from 'chart.js';

@connectTo()
@autoinject()
export class HomeNew {
  private me: User;
  private yapilyNewAccounts: Array<YapilyBankModel> = [];
  private allAccountsTogether: Array<IGenericAccount> = [];
  private selectedAccountIndex: number = 0;
  private state: State;
  private balance: number = 0;

  private listMenuItems: Array<MenuItem> = [];
  private title: string = "";
  private subtitle: string = "";
  private tabs: number = 0;
  private localeForCurrency = "fr-FR";
  private transactions: Array<IGenericTransaction> = [];
  private search_term: string = "";
  private loader: boolean = false;

  private copyPaymailButton;
  private copyPaymailButtonCheck;
  wallets: IGenericAccount[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private i18n: I18N,
    private singleton: SingletonService,
    private paymailWalletHttpClient: PaymailWalletClient,
    private eventAggregator: EventAggregator,
    private store: Store<State>
  ) {
    this.getMe();
 
  }

  async bind() {
    this.getLocaleForCurrency();
    if (this.state.wallets[this.state.selectedAccountIndex] == undefined){
      this.delay();
    }
    else {
      this.checkTransactions();
    }
  }

  delay() {
    
    setTimeout(() => (this.checkTransactions()), 3000);
  }
  checkTransactions() {
    this.loader = true;
    if (this.state && this.state.wallets && this.state.selectedAccountIndex !== undefined) {
      // Check if `this.state.wallets` is an array and `this.state.selectedAccountIndex` is within bounds
      if (Array.isArray(this.state.wallets) && this.state.selectedAccountIndex >= 0 && this.state.selectedAccountIndex < this.state.wallets.length) {
        // Access the transactions
        this.transactions = this.state.wallets[this.state.selectedAccountIndex].transactions;
        this.loader = false;
      } 
      else {
        this.loader = false;
      }
    }
    else {
      // Handle undefined state variables
      console.error("State variables 'wallets' or 'selectedAccountIndex' are undefined");
      this.loader = false;
    }
  }

  // SEARCH
  @computedFrom("transactions", "search_term")
  get search_results_transactions(): Array<IGenericTransaction> {
    if (!this.transactions) {
        return [];
    }

    if (this.search_term == "") {
        return this.transactions;
    }

    let searchTerm = this.search_term.toString().toLowerCase();

    return this.transactions.filter(function(item: IGenericTransaction) {
        return (
            item.createdAt.toString().toLowerCase().indexOf(searchTerm) > -1 ||
            (item.senderNote == undefined ? "" : item.senderNote).toString().toLowerCase().indexOf(searchTerm) > -1 ||
            item.amount.toString().toLowerCase().indexOf(searchTerm) > -1 ||
            item.senderIdentification.toLowerCase().indexOf(searchTerm) > -1 ||
            item.senderName.toLowerCase().indexOf(searchTerm) > -1 

        );
    });
  }

  getFontSize(event: Event): string {
    const target = event.target as HTMLElement;
    const containerWidth = target.offsetWidth;
    return `${containerWidth / 100 * 16}px`; // adjust font size based on container width
  }

  /** CanActivate
   * returns true if can show home
   * returns false if home should not be shown
   * redirects to the appropriate page if false
   */
  async canActivate() {
    try {
      this.me = await this.authService.getMe(); //ne pas enlever cette ligne please
      if (this.me.isFirstTime == true) {
        this.router.navigateToRoute("ftprofilepic");
        return false;
      }
    } catch (e) {
      this.router.navigateToRoute("login");
      return false;
    }
    return true;
  }

  async getMe() {
    this.me = await this.authService.getMe();
    this.store.dispatch("set.me.user", this.me);
  }

  getLocaleForCurrency() {
    const currentLocale = this.i18n.getLocale();
    switch (currentLocale) {
      case "fr":
        this.localeForCurrency = "fr-FR"; // 1 000 325,23
        break;
      case "de":
        this.localeForCurrency = "de-DE";
        break;
      case "en":
        this.localeForCurrency = "en-US"; // 1,000,325.23$
        break;
      default:
        this.localeForCurrency = "fr-FR";
        break;
    }
  }

  /**
   * Top menu items
   */
  fillMenuItems(newState) {
    let nbrWallets = newState.wallets.filter(account => account.isBlockchain).length;
    let nbrBankAccounts = newState.wallets.filter(account => !account.isBlockchain).length;
    const nbrTotalAccounts = newState.wallets.length;
    const allAccountsMenuItem = new MenuItem(
      this.i18n.tr("home.all"),
      "",
      nbrTotalAccounts
    );
    const bankAccountsMenuItem = new MenuItem(
      this.i18n.tr("home.banking"),
      "",
      nbrBankAccounts
    );
    const bitcoinWalletsMenuItem = new MenuItem(
      this.i18n.tr("bitcoin.bitcoin"),
      "",
      nbrWallets
    );
    this.listMenuItems = [];  
    this.listMenuItems.push(allAccountsMenuItem);
    this.listMenuItems.push(bankAccountsMenuItem);
    this.listMenuItems.push(bitcoinWalletsMenuItem);
    this.title = this.i18n.tr("home.hey_there") + this.me?.displayName + "!";
    this.subtitle = this.i18n.tr("home.welcome_back");
  }

  goToChooseWallet() {
    this.router.navigateToRoute("choose_wallet");
  }

  initiatePayment(account: IGenericAccount) {
    if(this.state.wallets.length > 0) {

      this.store.dispatch("set.make_payment.sender_wallet", account);
      this.router.navigateToRoute("payments");
    }
    else {
      this.router.navigateToRoute("choose_type_account");
    }
  }

  generateQrCode(account: IGenericAccount) {
    if (this.state.wallets.length > 0) {

      if (account.isBlockchain) {
        this.router.navigateToRoute("bitcoin_generate_qr_code");
      } else {
        this.router.navigateToRoute("generate_qr_code");
      }
    }
    else {
      this.router.navigateToRoute("choose_type_account");
    }
  }

  goToProfile() {
    this.router.navigateToRoute('profile');
  }

  goToTransactions() {
    if(env.environment === 'test') {
      this.router.navigateToRoute('transaction_comments');
    } else {
      this.router.navigateToRoute('transactions');
    }
  }

  stateChanged(newState: State, oldState) {
    this.loader = true;
    this.fillMenuItems(newState);
    if(newState.wallets.length > 0 && newState.wallets[newState.selectedAccountIndex])
      this.balance = newState.wallets[newState.selectedAccountIndex].balance;
    try{
      if(this.transactions){
        this.transactions = newState.wallets[newState.selectedAccountIndex].transactions;
        this.loader =false;
      }
    }catch(e){
      console.log(e)
    }
    this.checkTransactions()
  }

  copy() {
    navigator.clipboard.writeText(this.state.wallets[this.state.selectedAccountIndex].identification);
    this.copyPaymailButton.style.display = 'none';
    this.copyPaymailButtonCheck.style.display = 'block';

    setTimeout(() => {
      // Hide the second SVG image and show the first SVG image after 2 seconds
      this.copyPaymailButton.style.display = 'block';
      this.copyPaymailButtonCheck.style.display = 'none';
    }, 2000); // Set the delay in milliseconds (e.g., 2000 for 2 seconds)
  }

  redirectToWhatsOnChain(txid: string){
    // Opens in same tab
    // window.location.href = `https://whatsonchain.com/tx/${txid}`;

    // Opens in a new tab
    window.open(`https://whatsonchain.com/tx/${txid}`, '_blank');
  }
}
